.template-single-training {
  .template-content {
    padding-top: 4em;
    padding-bottom: 4em;

    .content {
      border: none;

      &-header {
        padding-bottom: 2em;
        margin-bottom: 2em;
        border-bottom: 1px solid color(--primary);
        color: var(--current-color);
        font-weight: $font-weight-medium;

        p {
          margin-bottom: 0;
        }
      }

      &-title {
        margin: 0;
        font-size: 2em;

        @include media-breakpoint-up(lg) {
          font-size: 3em;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 5em;
        }
      }

      &-upcoming {
        &-title {
          margin-top: 2.5em;
          font-weight: $font-weight-bold;
        }

        &-table {
          position: relative;
          z-index: 1;
          width: 100%;
          margin-bottom: 4em;
          border-left: 5px solid var(--current-color);
          background-color: rgba(var(--current-color-r), var(--current-color-g), var(--current-color-b), 0.18);

          @include media-breakpoint-up(md) {
            margin-bottom: var(--container-padding-lg);
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            background-color: $body-bg;
            opacity: 0.18;
          }

          thead th {
            font-weight: $font-weight-bold;
            text-align: left;
          }

          tbody {
            tr {
              position: relative;
              padding-bottom: 0.5em;

              &:not(:last-child)::after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                width: 100%;
                height: 0;
                border-bottom: 1px solid var(--current-color);
                opacity: 0.26;
              }
            }

            td {
              vertical-align: top;
              padding: 0.75rem 0.75rem 0 0.75rem;
              font-weight: $font-weight-bold;
              line-height: 1.15;

              @include media-breakpoint-up(md) {
                padding: 0.75rem;
              }

              @include media-breakpoint-up(xxl) {
                padding: 1.25rem;
                font-size: 1.2em;
              }

              &:first-child {
                padding-left: 1.5rem;

                @include media-breakpoint-up(lg) {
                  padding-left: 2.5rem;
                }
              }

              > span:first-child {
                color: var(--current-color);
                font-size: 0.6666em;
                font-weight: $font-weight-medium;
                white-space: nowrap;

                .icon {
                  display: inline-block;
                  font-size: 0.7em;
                  margin-right: 1em;
                }
              }

              > span > span:not(.icon) {
                font-weight: $font-weight-normal;
              }

              > span:not(.visually-hidden):not(.icon) {
                display: block;
              }

              .button.l-plain {
                background-color: var(--current-color);
                border-color: var(--current-color);
                color: color(--white);
                transition: opacity 0.35s ease;

                &:hover,
                &:focus {
                  opacity: 0.5;
                }
              }
            }
          }

          .table-cell {
            &-dates {
              @include media-breakpoint-up(md) {
                min-width: 7.5em;
                width: 7.5em;
              }

              @include media-breakpoint-up(lg) {
                width: auto;
              }
            }

            &-registration {
              text-align: right;
            }
          }

          @include media-breakpoint-down(md) {
            display: block;

            thead,
            thead tr,
            thead th,
            thead td,
            tbody,
            tbody tr,
            tbody th {
              display: block;
            }

            tbody {
              tr {
                display: flex;
                flex-wrap: wrap;
              }

              td:first-child {
                padding-left: 0.75rem;
              }

              .table-cell {
                &-dates {
                  //
                }

                &-location {
                  //
                }

                &-registration {
                  flex-grow: 1;
                  padding-bottom: 0.75rem;
                  text-align: right;
                }
              }
            }
          }
        }
      }

      &-description {
        display: flex;
        flex-direction: column;
        line-height: 1.3;

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }

        &-main {
          padding-bottom: 4em;

          @include media-breakpoint-up(md) {
            width: 66.6666%;
            padding-right: calc(var(--container-padding-lg) / 2);
            padding-bottom: 0;
          }
        }

        &-aside {
          @include media-breakpoint-between(sm, md) {
            display: flex;
            align-items: flex-start;
            width: 100%;
          }

          @include media-breakpoint-up(md) {
            width: 33.3333%;
            padding-left: calc(var(--container-padding-lg) / 2);
          }

          .button {
            &.l-plain {
              display: flex;
              align-items: center;
              width: 100%;
              padding: 1em 1.5em;
              margin-bottom: 2em;
              background-color: var(--primary);
              border-color: var(--primary);
              color: var(--white);
              text-align: left;
              text-transform: uppercase;
              font-size: 0.85rem;

              .icon {
                width: 1.5em;
                height: 1.5em;
                margin-left: 0;
                margin-right: 0.75em;
                background-color: color(--white);
                color: color(--primary);
                font-size: 1.5em;
                line-height: 1.5em;
                float: left;
              }

              &:hover,
              &:focus {
                background-color: color(--current-color);
                border-color: color(--current-color);
                color: color(--white);
                text-decoration: none;

                .icon {
                  background-color: color(--white);
                  color: color(--primary);
                }
              }

              span:last-child {
                flex-grow: 1;
                text-transform: uppercase;
              }
            }

            &:hover,
            &:focus {
              color: color(--white);

              .icon {
                background-color: color(--white);
              }
            }
          }

          a {
            color: color(--white);

            &:hover,
            &:focus {
              color: color(--white);
              text-decoration: underline;
            }
          }
        }

        &-timings {
          padding-bottom: 1em;
          border-bottom: 1px solid color(--primary);
          font-family: $font-family-narrow;
          font-size: 1.2em;

          .icon {
            display: inline-block;
            margin-right: 0.5em;
            font-size: 0.7em;
          }

          .separator {
            display: inline-block;
            vertical-align: middle;
            width: 1px;
            height: 1em;
            margin: 0 1em;
            background-color: color(--primary);
          }

          .timing:last-child {
            font-weight: $font-weight-medium;
          }
        }

        &-item {
          &:not(:last-child) {
            padding-bottom: 2.5em;
            border-bottom: 1px solid color(--primary);
          }

          .item {
            &-title {
              margin: 1.5em 0 1em 0;
              color: var(--current-color);
              font-weight: $font-weight-bold;
            }

            &-subtitle {
              margin: 0;
              color: var(--current-color);
              font-size: 0.8em;
              font-weight: $font-weight-bold;
            }

            &-text {
              font-family: $font-family-narrow;
              font-weight: $font-weight-bold;
            }
          }

          > ul {
            padding-left: 0;
            list-style: none;

            > li {
              &:not(:last-child) {
                margin-bottom: 0.75em;
              }

              > ul {
                list-style: disc;
              }
            }
          }
        }

        &-promoter {
          background-color: color(--current-color);
          color: color(--white);

          a {
            color: inherit;

            &:hover,
            &:focus {
              color: color(--secondary);
            }
          }

          @include media-breakpoint-between(sm, md) {
            margin-right: var(--container-padding-lg);
          }

          .promoter {
            &-title {
              margin: 0;
              font-family: $font-family-narrow;
              font-weight: $font-weight-bold;
              background-color: var(--primary);
              border-top: 2em solid var(--white);
              font-size: 0.85rem;

              @include media-breakpoint-up(xxxl) {
                font-family: $font-family-base;
              }

              .button {
                display: flex;
                align-items: center;
                padding: 1em 1.5em;
                text-align: left;
                transition: background-color 0.35s ease, color 0.35s ease;

                .icon {
                  width: 1.5em;
                  height: 1.5em;
                  margin-right: 0.75em;
                  background-color: color(--white);
                  color: color(--primary);
                  font-size: 1.5em;
                  line-height: 1.5em;
                  float: left;
                }

                &:hover,
                &:focus {
                  background-color: var(--current-color);
                  color: var(--white);
                  text-decoration: none;

                  &.icon {
                    background-color: var(--white);
                    color: var(--current-color);
                    text-decoration: none;
                  }
                }
              }

              span:last-child {
                flex-grow: 1;
                text-transform: uppercase;
              }
            }

            &-content {
              font-family: $font-family-narrow;

              > div {
                padding: 1em 1.5em;
              }

              &-title {
                margin: 0;
                font-family: $font-family-narrow;
                font-weight: $font-weight-bold;
                font-size: 1em;
              }
            }
          }
        }

        &-specs,
        &-formators {
          margin-top: 2em;
          padding: 1.5em;
          background-color: var(--current-color);
          color: color(--white);

          @include media-breakpoint-between(sm, md) {
            margin-top: 0;
            margin-left: var(--container-padding-lg);
          }

          @include media-breakpoint-up(md) {
            margin-top: 2em;
          }

          &:not(:last-child) {
            margin-bottom: 2em;

            @include media-breakpoint-between(sm, md) {
              margin-bottom: 0;
            }

            @include media-breakpoint-up(md) {
              margin-bottom: 2em;
            }
          }

          .specs-title {
            padding-bottom: 1em;
            margin: 0 0 1em 0;
            border-bottom: 1px solid color(--white);
            font-size: 1.3em;
            font-weight: $font-weight-bold;
          }

          p {
            span {
              &:first-child {
                font-size: 0.8em;
                font-weight: $font-weight-medium;
              }

              &:last-child {
                font-weight: $font-weight-bold;
              }

              &:not(.visually-hidden) {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
