/**
 * Import TailwindCSS
 */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/**
 * node_modules imports
 */
@import '~normalize.css';

/**
 * Mixins
 */
@import 'styles/mixins/breakpoints';
@import 'styles/mixins/css-vars';

/**
 * Variables
 */
@import 'styles/variables';

/**
 * App imports
 */
@import 'styles/a11y';
@import 'styles/global';
@import 'styles/typography';
@import 'styles/animations';
@import 'styles/wp-classes';
@import 'styles/components/images/logo-unipop';
@import 'styles/components/images/picto-unipop';
@import 'styles/components/loader';
@import 'styles/components/container';
@import 'styles/components/section';
@import 'styles/components/icon';
@import 'styles/components/button';
@import 'styles/components/link';
@import 'styles/components/image';
@import 'styles/components/menu';
@import 'styles/components/filter';
@import 'styles/components/date-filter';
@import 'styles/components/modal';
@import 'styles/components/trainingModalForm';
@import 'styles/components/trainings-table';
@import 'styles/components/pagination';
@import 'styles/components/collapsable';
@import 'styles/components/forms/contact';
@import 'styles/components/forms/form';
@import 'styles/components/forms/checkbox-group';
@import 'styles/components/forms/radio-group';
@import 'styles/components/layouts/header';
@import 'styles/components/layouts/header/nav-primary';
@import 'styles/components/layouts/header/form-search';
@import 'styles/components/layouts/header/form-newsletter';
@import 'styles/components/layouts/footer';
@import 'styles/components/layouts/page-header';
@import 'styles/components/layouts/section-trainings';
@import 'styles/components/layouts/section-cta';
@import 'styles/components/layouts/section-news';
@import 'styles/components/layouts/section-feeds';
@import 'styles/components/layouts/section-flexible';
@import 'styles/components/layouts/section-gift-voucher';
@import 'styles/components/layouts/section-accordion';
@import 'styles/components/layouts/section-contact';
@import 'styles/components/layouts/section-partners';
@import 'styles/components/layouts/section-organizations';
@import 'styles/components/layouts/section-trainings-list';
@import 'styles/components/template';
@import 'styles/components/templates/front-page';
@import 'styles/components/templates/single-training';
@import 'styles/components/templates/single-featured-course';
@import 'styles/components/templates/trainings';
@import 'styles/components/templates/news';
@import 'styles/components/templates/featured-courses';
@import 'styles/components/templates/formal-adult-education';
@import 'styles/components/templates/unipop';
@import 'styles/components/templates/infos';
@import 'styles/components/templates/page-404';
@import 'styles/components/toggle';
@import 'styles/components/layouts/layout';
@import 'styles/components/print';
