.button {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: 1px dotted transparent;
  color: inherit;
  font: inherit;
  line-height: $line-height-base;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  transition: color 0.2s ease-out, outline 0.2s ease-out, border-color 0.2s ease-out;

  &:not(.is-disabled):not([disabled]):hover,
  &:not(.is-disabled):not([disabled]):focus {
    cursor: pointer;
    color: color(--secondary);

    /* border: 1px solid color(--secondary); */
  }

  &.is-disabled {
    cursor: inherit;
  }

  &.l-square-outline {
    display: inline-block;
    padding: 0.75em 1.5em;
    border: 1px solid color(--primary);
    color: color(--primary);
    font-weight: $font-weight-bold;

    .l-dark & {
      color: color(--secondary);
      border: 1px solid color(--secondary);

      &:not(.is-disabled):hover,
      &:not(.is-disabled):focus {
        color: color(--white);
        border: 1px solid color(--white);
      }
    }

    &.selected {
      color: var(--secondary);
    }
  }

  &.l-plain {
    display: inline-block;
    padding: 0.75em 1.5em;
    background-color: color(--secondary);
    border: 1px solid color(--secondary);
    color: color(--primary);
    font-weight: $font-weight-bold;
    transition: border-color 0.35s ease, background-color 0.35s ease;

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus {
      border-color: color(--primary);
    }

    .icon {
      display: inline-block;
      margin-left: 0.25em;
      font-size: 0.75em;
    }
  }

  .overlay-group {
    .button {
      &.l-plain {
        background-color: transparent;
        border-color: var(--primary);
        color: var(--primary);
        text-decoration: none;
      }
    }
  }

  &.overlay-button {
    padding: 0.5em;
    font-weight: $font-weight-medium;

    @include media-breakpoint-up(sm) {
      padding: 0.5em 1em;
    }

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus {
      outline: 1px dotted color(--secondary);
      color: color(--secondary);
    }

    .icon {
      display: inline-block;
      margin-left: 0.75em;
      transform: translateY(-0.125em);
      font-size: 0.5em;
    }
  }
}
