.gift-voucher {
  background-color: var(--primary);
  padding: 0;

  .container {
    padding: 0;
    position: relative;
  }

  .happy-person {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;

    @media (max-width: 1250px) {
      z-index: -1;
    }
  }

  .step-indicator {
    margin-bottom: 2rem;
    height: 90px;
    background-color: rgba(255, 255, 255, 0.1);

    @media (max-width: 640px) {
      height: 45px;
    }

    .step-progress {
      height: 100%;
      border-radius: 2px;
      position: relative;
      margin: 0 auto;
      max-width: 800px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 640px) {
        max-width: 600px;
      }

      &-container {
        height: 100%;
      }

      .progress-step-indicator {
        height: 100%;
        background-color: transparent;
        border-radius: 2px;
        left: 0;
        top: 0;
        transition: all 0.3s ease;
        color: var(--white);
        width: 10%;
        margin: 0 5%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        font-weight: 600;

        &.active {
          width: 10%;
          margin: 0 5%;
          background-color: var(--secondary);
          border-top-right-radius: 50%;
          border-top-left-radius: 50%;
          color: var(--primary);
        }
      }
    }
  }

  .step-content {
    max-width: 600px;
    min-height: 450px;
    margin: 0 auto;
    padding: 2rem 0;
    scroll-margin-top: 20px;

    h2 {
      margin-bottom: 2rem;
      text-align: center;
      color: var(--white);
      text-transform: uppercase;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 50px;
        height: 50px;
        background-image: url('../../../images/curved_line.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 50%;
        position: absolute;
        right: 0px;
        top: -20px;
      }
    }

    button {
      &.edit,
      &.back {
        border: 2px solid var(--white);
        border-radius: 0;
        color: var(--white);
        background-color: transparent;
        margin: 2rem auto;
        margin-top: 4rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        &.button-disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }

        @media (hover: hover) {
          &:hover {
            color: var(--primary) !important;
            background-color: var(--white) !important;
          }
        }

        @media (max-width: 640px) {
          padding: 0.5rem;
        }
      }

      &.confirm,
      &.preview,
      &.next {
        border: 2px solid var(--secondary);
        border-radius: 0;
        color: var(--primary);
        background-color: var(--secondary);
        margin: 2rem auto;
        margin-top: 4rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        &.button-disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }

        &:active {
          opacity: 0.8;
          color: var(--primary) !important;
          background-color: var(--secondary) !important;
        }

        @media (hover: hover) {
          &:hover {
            color: var(--primary) !important;
            background-color: var(--secondary) !important;
          }
        }
      }
    }

    label {
      display: block;
      margin-bottom: 0.5rem;
      color: var(--white);
    }

    input {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 6px;
      border: none;
      padding: 1rem;
      color: var(--white);
      height: 20px;
      width: 100%;
    }

    input[type='radio'],
    input[type='checkbox'] {
      width: inherit;
      padding: inherit;
      margin: inherit;
    }

    .form-field {
      width: 100%;

      @media (max-width: 640px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .form-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      margin-bottom: 2rem;
      justify-content: center;
      align-items: center;
      width: 100%;

      @media (max-width: 640px) {
        grid-template-columns: 1fr;
        text-align: center;
      }

      label {
        text-align: left;
      }
    }

    i {
      font-size: 0.8rem;
      margin-left: 1rem;
      display: inline-block;
    }

    .amount-options {
      max-width: 600px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      margin: 2rem auto;

      label {
        text-align: center;
      }

      button {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 12px;
        height: 80px;
        color: var(--white);
        font-size: 1.5rem;
        font-weight: 600;
        margin-top: 2rem;
        margin-bottom: 2rem;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover,
        &.selected {
          background-color: var(--secondary);
          color: var(--primary);
        }

        @media (max-width: 640px) {
          padding: 0.5rem;
        }
      }
    }

    .custom-amount {
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 60%;
      margin: 0 auto;
      min-width: 320px;
    }

    .delivery-method {
      @media (max-width: 640px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      label.title {
        border-bottom: 2px solid #fff;
        padding-bottom: 12px;
        margin-bottom: 1.5rem;
      }

      .radio-group {
        display: flex;
        gap: 1rem;
      }
    }

    .designs-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      margin-bottom: 2rem;
      align-content: center;
      justify-content: center;

      @media (max-width: 640px) {
        grid-template-columns: 1fr;
        margin: 0 1rem 2rem;
      }

      .design-option {
        cursor: pointer;
        border: 2px solid transparent;
        padding: 0.5rem;
        transition: all 0.3s ease;
        background-color: rgba(255, 255, 255, 0.1);

        &.selected {
          border-color: var(--secondary);
          background-color: var(--secondary);
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .voucher-preview {
      margin-bottom: 2rem;
      padding: 2rem;
      background-color: rgba(255, 255, 255, 0.1);
      min-width: 800px;
      margin-left: -100px;

      @media (max-width: 640px) {
        min-width: 100%;
        margin-left: 0;
      }
    }

    .preview-container {
      position: relative;

      .preview-overlay {
        color: var(--white);
        text-align: center;

        .amount {
          font-size: 1.5rem;
          font-weight: 600;
          top: 30.5%;
          color: #000000;
          left: 50%;
          transform: translateX(-50%);
          position: absolute;

          @media (max-width: 540px) {
            margin-top: -1.5%;
          }
        }

        .recipient {
          font-size: 1.2rem;
          font-weight: 600;
          top: 39.5%;
          color: #000000;
          left: 50%;
          transform: translateX(-50%);
          position: absolute;

          @media (max-width: 540px) {
            margin-top: -1.5%;
          }
        }

        .sender {
          font-size: 1rem;
          font-weight: 400;
          top: 48%;
          color: #000000;
          left: 50%;
          transform: translateX(-50%);
          position: absolute;

          @media (max-width: 540px) {
            margin-top: -1.5%;
          }
        }
      }
    }

    .button-disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    .field-text {
      position: relative;

      input.error {
        border-color: #f44;
      }

      .error-message {
        color: #f44;
        font-size: 0.8rem;
        margin-top: 0.25rem;
        position: absolute;
        bottom: -1.5rem;
      }
    }

    .country-select {
      margin: 1rem 0 2rem;
      width: 100%;

      label {
        display: block;
        margin-bottom: 0.5rem;
        color: var(--white);
      }

      select {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 6px;
        border: none;
        padding: 0 1rem;
        color: var(--white);
        height: 45px;
        cursor: pointer;

        option {
          background-color: var(--primary);
          color: var(--white);
        }
      }

      @media (max-width: 640px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  .summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto;
    color: var(--white);
    max-width: 600px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    border-top: 5px solid var(--secondary);

    h3 {
      margin-bottom: 1rem;
      text-transform: uppercase;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &-items {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      p {
        margin: 0;
        width: 50%;
      }
    }
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
    max-width: 600px;

    button {
      &.edit,
      &.back {
        border: 2px solid var(--white);
        border-radius: 0;
        color: var(--white);
        background-color: transparent;
        margin: 2rem auto;
        margin-top: 4rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        &.button-disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }

        @media (hover: hover) {
          &:hover {
            color: var(--primary) !important;
            background-color: var(--white) !important;
          }
        }

        @media (max-width: 640px) {
          padding: 0.5rem;
        }
      }

      &.confirm,
      &.preview,
      &.next {
        border: 2px solid var(--secondary);
        border-radius: 0;
        color: var(--primary);
        background-color: var(--secondary);
        margin: 2rem auto;
        margin-top: 4rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        &.button-disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }

        &:active {
          opacity: 0.8;
          color: var(--primary) !important;
          background-color: var(--secondary) !important;
        }

        @media (hover: hover) {
          &:hover {
            color: var(--primary) !important;
            background-color: var(--secondary) !important;
          }
        }
      }
    }
  }

  .design_preview_disclaimer {
    font-size: 0.5rem;
    margin-top: 0;
    color: var(--white);
    display: block;
  }

  .disclaimer {
    margin: 2rem 0;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.1);

    &-checkbox {
      display: flex !important;
      align-items: flex-start;
      gap: 1rem;
      color: var(--white);
      font-size: 0.9rem;

      input[type='checkbox'] {
        margin-top: 0.25rem;
        min-width: 25px;
      }
    }
  }
}
