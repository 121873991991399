.template-unipop,
.template-page {
  .section.flexible {
    .flexible-content {
      .text {
        &.l-background {
          li:not(:last-child) {
            margin-bottom: 1.5em;
          }

          li:last-child {
            @include media-breakpoint-down(md) {
              margin-bottom: 1.5em;
            }
          }

          .text-text h4,
          .text-text h5,
          .text-text h6 {
            margin-bottom: 2em;
          }
        }
      }

      .text-image {
        padding: calc(var(--container-padding-lg) / 2) 0;
      }

      &:nth-child(2) {
        .text-image {
          padding-bottom: var(--container-padding-lg);
        }
      }

      &:nth-child(3) {
        background-color: rgba(var(--primary-r), var(--primary-g), var(--primary-b), 0.06);

        .text-image {
          padding: var(--container-padding-lg) 0;
        }
      }

      &:nth-child(4) {
        .text-image {
          padding-top: var(--container-padding-lg);
        }
      }

      &:last-child {
        .cols-2-cols {
          .cols-col {
            &:first-child {
              @include media-breakpoint-up(md) {
                width: 60%;
              }
            }

            &:last-child {
              padding-top: 0.5em;
              padding-bottom: 1em;

              @include media-breakpoint-up(md) {
                width: 40%;
                border-left: 1px solid color(--primary);
              }
            }
          }
        }
      }
    }
  }
}
