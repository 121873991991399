.l-dark {
  color: var(--white);

  .header:not(.is-scrolled) {
    .header-content {
      color: var(--white);
    }
  }

  .page-header {
    &-headline {
      .content {
        margin: 0;
        padding: 0;
        width: 100%;
        margin-left: -1px;
      }
    }
  }

  .section {
    &-title {
      color: color(--secondary);
    }
  }

  footer {
    background-color: var(--white);
    color: var(--primary);

    .logo-unipop {
      &.svg {
        svg {
          .text {
            fill: var(--primary);
          }
        }
      }
    }

    a {
      color: var(--primary);

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }

    .button {
      &.l-square-outline {
        color: var(--secondary);
        border: 1px solid var(--secondary);
      }
    }
  }

  &.section.flexible {
    .flexible-content {
      .overlay {
        &-content {
          &::after {
            background-color: var(--white);
          }
        }
      }
    }
  }
}

.l-light {
  color: var(--primary);

  .header:not(.is-scrolled) {
    .header-content {
      color: var(--primary);
    }
  }

  .page-header {
    &-headline {
      .content {
        margin: 0;
        padding: 0;
        width: 100%;
        margin-left: -1px;
      }

      &-top {
        .content-top {
          &-title {
            &::after {
              background: url('../../../images/logo-unipop-outline-blue.svg') no-repeat center top / contain;
            }
          }
        }
      }
    }
  }

  .theme-toggle {
    background-color: var(--primary);

    .icon {
      &.sun {
        svg {
          path {
            fill: var(--primary);
          }
        }
      }

      &.moon {
        svg {
          path {
            fill: var(--white);
          }
        }
      }
    }
  }

  .section {
    &-title {
      color: color(--primary);
    }

    &.flexible {
      .flexible-content {
        .button {
          &.l-plain {
            background-color: transparent;
            border-color: var(--current-color);
            color: var(--current-color);
          }
        }

        .overlay-group {
          .button {
            &.l-plain {
              background-color: transparent;
              border-color: var(--white);
              color: var(--white);
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .flexible {
    .flexible-content {
      .overlay {
        &-content {
          &::after {
            background-color: var(--primary);
          }
        }
      }
    }
  }
}

.section.l-dark {
  &-title {
    color: color(--white);
  }

  &.flexible {
    .flexible-content {
      .button {
        &.l-plain {
          background-color: transparent;
          border-color: var(--current-color);
          color: var(--current-color);
        }
      }

      .overlay-group {
        .button {
          &.l-plain {
            background-color: transparent;
            border-color: var(--primary);
            color: var(--primary);
            text-decoration: none;
          }
        }
      }
    }
  }
}
